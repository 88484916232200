<template>
<div class="dash">
    <panelGroup></panelGroup>
    <el-row class="chart" style="background-color: #ffffff;">
        <lineChart></lineChart>
    </el-row>
    <el-row class="chart">
        <el-col>
           <pieChart title="应急报警来源分布"></pieChart>
        </el-col>
        <el-col style="float:right">
            <pieChart title="应急报警类型分布"></pieChart>
        </el-col>
    </el-row>
</div>
</template>
<script>
import lineChart from './lineChart'
import pieChart from './pieChart'
import panelGroup from './panelGroup'
export default {
  components: {
      lineChart,
      pieChart,
      panelGroup
  }
};
</script>

<style lang="scss" scoped>
.dash{
    .chart{
      width: 100%;
      height: 400px;
      margin-top: 20px;
    }
}
.el-col{
    height: 100%;
    margin: 0 5px;
    background-color: #ffffff;
    width: 49%;
}
</style>