<template>
    <div class="homeSum">
        <div class="homelayout">
            <div class="homeitem" v-for="(one) in list" v-bind:key="one.id" @click="click(one.path,one.prop)">
                <img :src="one.url" />
                <div class="text">
                    <div class="number">{{one.num}}</div>
                    <div>{{one.info}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    props: {
        list: {
            type: Array,
            default() {
                return []
            },
        },
    },
    methods: {
        click(path, prop) {
            if (path) {
                this.$emit(path, prop)
            }
        },
    },
}
</script>
<style lang="scss" scoped="homeSum">
.homeSum {
    box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    padding: 20px 15px;
    .homelayout {
        display: flex;
        flex-wrap: wrap;
        .homeitem {
            // min-width: 180px;
            cursor: pointer;
            flex: 1;
            display: inline-flex;
            // padding-bottom: 10px;
            .text {
                padding-left: 10px;
                .number {
                    font-weight: bold;
                    font-size: 25px;
                    padding-bottom: 5px;
                }
                div {
                    + div {
                        font-size: 18px;
                        color: #bbbbbb;
                        font-weight: bold;
                    }
                }
            }
            + .homeitem {
                border-left: 1px solid #ebebeb;
                padding-left: 10px;
            }
        }
    }
}
</style>


