<template>
    <div class="title_text">
        <homeSum :list="summary" class="text_box"></homeSum>
    </div>
</template>
<script>
import homeSum from './homeSum'
export default {
    props: {
        pageName: {
            type: String,
            default: 'dashboard',
        },
    },
    components: {
        homeSum,
    },
    data() {
        return {
            summary: [
                {
                    url: require('@/assets/dash/alarmTotal.png'),
                    info: '今日应急报警总数(次)',
                    num: 0,
                    path: 'realTime',
                    prop: '',
                },
                {
                    url: require('@/assets/dash/handledTotal.png'),
                    info: '今日已处理总数(次)',
                    num: 0,
                    path: 'realTime',
                    prop: '',
                },
                {
                    url: require('@/assets/dash/handlingTotal.png'),
                    info: '今日处理中总数(次)',
                    num: 0,
                    path: 'intelligent',
                    prop: 1,
                },
                {
                    url: require('@/assets/dash/unhandleTotal.png'),
                    info: '今日未处理总数(次)',
                    num: 0,
                    path: 'intelligent',
                    prop: 3,
                },
                {
                    url: require('@/assets/dash/handleRate.png'),
                    info: '报警处理率(%)',
                    num: '0%',
                    path: 'intelligent',
                    prop: 4,
                },
            ],
            interval: null,
        }
    },
    async created() {
        await this.initDashInfo()
    },
    watch: {
        $route: {
            handler: function(to) {
                console.log(to)
                if ('dashboard' == to.name) {
                    this.interval && window.clearInterval(this.interval)
                    this.interval = setInterval(() => {
                        this.initDashInfo()
                    }, 10 * 1000)
                } else {
                    this.interval && window.clearInterval(this.interval)
                }
            },
            immediate: true,
        },
    },
    methods: {
        async initDashInfo() {
            if (this.$route.path == '/dashboard') {
                this.$http({
                   url: "/home/dayAlarmNumber"
                }).then(res => {
                    res.detail && res.detail.forEach(v => {
                        this.summary[0].num += v.list.alarmAllNum;
                        if (v.list.processedNum) {
                            this.summary[1].num += v.list.processedNum;
                        }
                        if (v.list.inProcessedNum) {
                            this.summary[2].num += v.list.inProcessedNum;
                        }
                        this.summary[3].num += v.list.untreatedNum;
                    })
                    this.summary[4].num = ((this.summary[1].num/this.summary[0].num) * 100).toFixed(2) + "%"
                })
            }
        },
    }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped="panel-com">
.icon_midd {
    width: 3px;
    height: 89px;
    margin-top: 20px;
    margin-bottom: 30px;
}

.split-line {
    color: #dddddd;
    height: 53px;
    float: right;
    margin-top: 28px;
    line-height: 16px;
    font-size: 14px;
    border-left: 1px solid #dddddd;
}

.icon-icon {
    width: 55px;
    height: 55px;
}

.panel-group {
    min-width: 1350px;

    .card-panel-col {
        float: left;
        margin-bottom: 20px;
    }

    .card-panel {
        height: 108px;
        cursor: pointer;
        font-size: 16px;
        position: relative;
        // overflow: hidden;
        color: #666;
        background: #fff;
        box-shadow: 3px 3px 5px #f3f4f5;
        border-color: rgba(0, 0, 0, 0.05);

        &:hover {
            .card-panel-icon-wrapper {
                color: #fff;
            }

            .icon-people {
                background: #40c9c6;
            }

            .icon-message {
                background: #36a3f7;
            }

            .icon-money {
                background: #f4516c;
            }

            .icon-shoppingCard {
                background: #34bfa3;
            }
        }

        .icon-people {
            color: #40c9c6;
        }

        .icon-message {
            color: #36a3f7;
        }

        .icon-money {
            color: #f4516c;
        }

        .icon-shoppingCard {
            color: #34bfa3;
        }

        .card-panel-icon-wrapper {
            float: left;
            margin: 14px 0 0 14px;
            padding: 16px 1px;
            transition: all 0.38s ease-out;
            border-radius: 6px;
        }

        .online-rate-wrapper {
            float: left;
            margin: 13px 0 0 13px;
            padding: 16px 1px;
            transition: all 0.38s ease-out;
            border-radius: 6px;
        }

        .card-panel-icon {
            float: left;
            font-size: 48px;
        }

        .card-panel-description {
            float: left;
            font-weight: bold;
            margin-top: 26px;
            margin-left: 15px;

            .card-panel-text {
                line-height: 18px;
                color: #b4bbcf;
                font-size: 18px;
                margin-bottom: 12px;
            }

            .card-panel-num {
                text-align: center;
                font-size: 26px;
                margin-bottom: 12px;
            }
        }

        .online-rate-description {
            float: left;
            font-weight: bold;
            margin-top: 26px;
            margin-left: 15px;

            .card-panel-text {
                line-height: 18px;
                color: #b4bbcf;
                font-size: 18px;
                margin-bottom: 12px;
            }

            .card-panel-num {
                text-align: center;
                font-size: 50px;
                margin-bottom: 12px;
            }
        }
    }
}
// .title_text {
//     .text_box {
//         margin: 20px 0;
//     }
// }
</style>
