<template>
  <div class="pie">
    <div class="head">
      <span style="padding-left:20px;">{{ title }}</span>
      <selectOrgCascader
        style="float:right;padding-top:5px;padding-right:10px;"
        v-if="title.includes('类型')"
        @selctOrg="selctOrg"
      ></selectOrgCascader>
    </div>
    <div class="time">
      <el-button type="text" size="mini" @click="changeTime(1)">今日</el-button>
      <el-button type="text" size="mini" @click="changeTime(2)">本周</el-button>
      <el-button type="text" size="mini" @click="changeTime(3)">本月</el-button>
      <el-date-picker
        v-model="startEnd"
        type="datetimerange"
        value-format="yyyyMMddHHmmss"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      ></el-date-picker>
    </div>
    <div class="pieChart" ref="pieChart"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import selectOrgCascader from "@/components/dialog/selectOrgCascader";
import _ from "loadsh";
import { initDate } from "@/utils/date";
export default {
  props: {
    title: {
      type: String,
      required: true
    }
  },
  name: "pieChart",
  watch: {
    startEnd() {
      this.listQuery.startDate = this.startEnd[0];
      this.listQuery.endDate = this.startEnd[1];
      this.initData();
    }
  },
  methods: {
    selctOrg(orgId) {
      this.listQuery = orgId;
      this.initData();
    },
    initData() {
      if (this.title.includes("类型")) {
        this.initAlarmType(this.listQuery);
      } else {
        this.initAlarmSource(this.listQuery);
      }
    },
    changeTime(type) {
      if (type === 1) {
        //日期初始化为天
        const now = new Date();
        this.startEnd = [
          initDate(now, false, "yyyyMMddhhmmss"),
          initDate(now, true, "yyyyMMddhhmmss")
        ];
      } else if (type === 2) {
        //日期初始化为周
        const date = new Date();
        date.setDate(date.getDate() - 6);
        const dataEnd = new Date();
        this.startEnd = [
          initDate(date, false, "yyyyMMddhhmmss"),
          initDate(dataEnd, true, "yyyyMMddhhmmss")
        ];
      } else {
        //日期初始化为月
        const date = new Date();
        date.setDate(date.getDate() - 30);
        const dataEnd = new Date();
        this.startEnd = [
          initDate(date, false, "yyyyMMddhhmmss"),
          initDate(dataEnd, true, "yyyyMMddhhmmss")
        ];
      }
    },
    draw() {
      if (_.isEmpty(this.data)) {
        return false;
      }
      // 实例化echarts对象
      this.chart = echarts.init(this.$refs.pieChart);
      const option = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          type: "scroll",
          orient: "vertical",
          right: 10,
          top: 20,
          bottom: 20,
          data: this.data.legendData
        },
        series: [
          {
            name: "报警",
            type: "pie",
            radius: "55%",
            center: ["40%", "50%"],
            data: this.data.seriesData,
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      };
      // 绘制条形图
      this.chart.setOption(option);
    },
    async initAlarmSource(listQuery) {
      const result = await this.$http({
          url: "/home/alarmSource",
          data: listQuery
      });
      this.data = this.dataMachin(result.detail);
    },
    async initAlarmType(listQuery) {
      const result = await this.$http({
          url: "/home/alarmType",
          data: listQuery
      });
      this.data = this.dataMachin(result.detail);
    },
    dataMachin(data) {
      const isType = this.title.includes("类型")
      let machinData = {
        seriesData: [],
        legendData: []
      };
      _.forEach(data, function(v) {
        machinData.seriesData.push({
          name: isType? v.alarmType:v.alarmSource,
          value: v.percentage
        });
        machinData.legendData.push(isType? v.alarmType:v.alarmSource);
      });
      return machinData;
    }
  },
  mounted() {
    this.changeTime(1);
    // 调用绘制图表的方法
    const vm = this;
    this.$nextTick(() => {
      vm.draw();
      vm.__resizeHanlder = _.debounce(() => {
        if (vm.chart) {
          vm.chart.resize();
        }
      }, 100);
      window.addEventListener("resize", this.__resizeHanlder);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.__resizeHanlder);
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  data() {
    return {
      chart: null,
      data: {
        legendData: [],
        seriesData: []
      },
      listQuery: {
        startDate: "",
        endDate: "",
        orgId: ""
      },
      startEnd: []
    };
  },
  components: {
    selectOrgCascader
  }
};
</script>

<style lang="scss" scoped>
.pie {
  width: 100%;
  height: 100%;
  .head {
    width: 100%;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid rgb(211, 208, 208);
  }
  .time {
    float: left;
    margin-top: 5px;
    margin-left: 20px;
    z-index: 1;
  }
  .pieChart {
    height: calc(100% - 45px);
    width: 100%;
    z-index: -1;
  }
}
</style>
