<template>
  <div class="lineChart">
    <div class="head">
      <span style="padding-left:20px">{{title}}</span>
    </div>
    <div class="time">
      <el-button type="text" size="mini" @click="getTime('日')">日</el-button>
      <el-button type="text" size="mini" @click="getTime('周')">周</el-button>
      <el-button type="text" size="mini" @click="getTime('月')">月</el-button>
      <el-button type="text" size="mini" @click="getTime('年')">年</el-button>
    </div>
    <div class="oneAlertCount" ref="oneAlertCount"></div>
  </div>
</template>

<script>
import echarts from "echarts";
import "echarts/lib/chart/bar";
import _ from "loadsh";
const totalSerials =  [
  { key: 'alarmAllNum',name: '报警总数', color: '#49A9EE' },
  { key: 'inProcessedNum',name: '已处理', color: '#98D87D' },
  { key: 'processedNum',name: '处理中', color: '#996666' },
  { key: 'untreatedNum',name: '未处理', color: '#FF0000' },
  ]
export default {
  name: "lineChart",
  methods: {
    async getTime(dateType) {
      this.title = `${this.orignTitle}(${dateType})`;
      let result = null
      if (dateType === "日") {
        this.type = 'day'
        result = await this.$http({url:"/home/dayAlarmNumber"});
      } else if (dateType === "周") {
        this.type = 'week'
        result = await this.$http({url:"/home/weekAlarmNumber"});
      } else if (dateType === "月") {
        this.type = 'month'
        result = await this.$http({url: "/home/monthAlarmNumber"});
      } else {
        this.type = 'year'
        result = await this.$http({url: "/home/yearAlarmNumber"});
      }
      this.data = this.machinData(result.detail);
      this.draw()
    },
    draw() {
      if (_.isEmpty(this.data)) {
        return;
      }
      this.chart = echarts.init(this.$refs.oneAlertCount);
      const option = {
        xAxis: {
          type: "category",
          data: this.data.categoryData
        },
        yAxis: {
          type: "value"
        },
        // tooltip: {
        //   tragger: 'item',
        //   formatter: 'a:b'
        // }, 
        series: this.data.seriesData
      };
      this.chart.setOption(option);
    },
    machinData(data) {
      let machinData = {
        seriesData: [],
        categoryData: []
      };
      machinData.categoryData = data.map(item => {
        if (this.type === 'day') {
          return item.dateTime.slice(0,2) + ':' + item.dateTime.slice(3,5)
        } else if(this.type === 'week') {
          return item.dateTime.slice(0,2) + '-' + item.dateTime.slice(3,4)
        } else if (this.type === 'month'){
          return item.dateTime.slice(0,2) + '-' + item.dateTime.slice(3,4)
        }else {
          return item.dateTime.slice(3)
        }
      })
      machinData.seriesData = totalSerials.map(item => {
        const key = item.key
        return {
          name: item.name,
          itemStyle: {
            color: item.color
          },
          type: 'line',
          data: data.map(v => {
            return v.list[key]
          })
        }
      })
      return machinData;
    },
  },
  data() {
    return {
      type: 'day',
      chart: null,
      orignTitle: "应急报警数汇总",
      title: "应急报警数汇总(日)",
      data: {
        categoryData: [],
        seriesData: []
      },
    };
  },
  mounted() {
    // 调用绘制图表的方法
    this.getTime('日');
    const vm = this;
    vm.__resizeHanlder = _.debounce(() => {
      if (vm.chart) {
        vm.chart.resize();
      }
    }, 100);
    window.addEventListener("resize", this.__resizeHanlder);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.__resizeHanlder);
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.lineChart {
  width: 100%;
  height: 100%;
  .head {
    width: 100%;
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid rgb(211, 208, 208);
  }
  .time {
    float: left;
    margin-top: 5px;
    margin-left: 20px;
    z-index: 1;
  }
  .oneAlertCount {
    height: calc(100% - 45px);
    width: 100%;
    z-index: -1;
  }
}
</style>
